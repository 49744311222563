//ingredients
import beef from '../assets/ingredients/beef.png';
import cheese from '../assets/ingredients/cheese.png';
import spicyfood from '../assets/ingredients/chilli.png';
import corn from '../assets/ingredients/corn.png';
import dairy from '../assets/ingredients/dairy.png';
import eggs from '../assets/ingredients/eggs.png';
import fish from '../assets/ingredients/fish.png';
import highfodmap from '../assets/ingredients/fodmap.png';
import fruit from '../assets/ingredients/fruit.png';
import garlic from '../assets/ingredients/garlic.png';
import gluten from '../assets/ingredients/gluten.png';
import highsugar from '../assets/ingredients/highsugar.png';
import honey from '../assets/ingredients/honey.png';
import highketo from '../assets/ingredients/keto.png';
import legumes from '../assets/ingredients/legumes.png';
import mushrooms from '../assets/ingredients/mushrooms.png';
import nightshades from '../assets/ingredients/nightshades.png';
import onion from '../assets/ingredients/onion.png';
import peanut from '../assets/ingredients/peanut.png';
import pork from '../assets/ingredients/pork.png';
import potato from '../assets/ingredients/potato.png';
import redmeat from '../assets/ingredients/redmeat.png';
import rice from '../assets/ingredients/rice.png';
import seafood from '../assets/ingredients/seafood.png';
import sesame from '../assets/ingredients/sesameseeds.png';
import shellfish from '../assets/ingredients/shellfish.png';
import soy from '../assets/ingredients/soy.png';
import tofu from '../assets/ingredients/tofu.png';
import treenuts from '../assets/ingredients/treenuts.png';
import wheat from '../assets/ingredients/wheat.png';
import whitemeat from '../assets/ingredients/whitemeat.png';

export const GET_RESTAURANT_INFO_URL = (url) => '/api/verifiedRestaurant/' + url;
export const CLICK_BUTTON_URL = (id) => '/api/click/' + id;
export const CLICK_DIET_URL = () => '/api/diet';
export const CLICK_ALLERGEN_URL = () => '/api/allergen';

export const DISCLAIMER =
	'Menu items and ingredients are actively verified by restaurant staff. However, this menu is only a guide. Always confirm ingredients with our staff when ordering. Please read our ';
export const DISCLAIMER_PROFILER = 'By hitting done you agree to our ';

export const DISCLAIMER_UNVERIFIED = 'WARNING! Menu items and ingredients are NOT verified by restaurant staff. This menu is only a guide. Always confirm ingredients with our staff when ordering. Please read our ';

export const IMAGE_URL = (image) =>
	'https://s3-us-west-2.amazonaws.com/honeycomb.ai/restaurants/restaurant' + image + '.jpg';
export const ITEM_IMAGE_URL = (image) => 'https://s3-us-west-2.amazonaws.com/honeycomb.ai/item/' + image + '.jpg';

export const STATUS_OK = 200;
export const DIETS = [
	'Allergy Friendly',
	'Veggie Friendly',
	'100% Vegetarian',
	'Vegan Friendly',
	'100% Vegan',
	'Gluten Free Friendly',
	'Tamari Sauce',
	'Dedicated Gluten Free Fryer',
	'100% Gluten Free'
];

export const PROTEINS = {
	Beef: 0b00000000100000000000000000000000,
	'Red Meat': 0b00000000010000000000000000000000,
	'White Meat': 0b00000000001000000000000000000000,
	Pork: 0b00000000000100000000000000000000,
	Fish: 0b00000000000010000000000000000000,
	'Other Seafood': 0b00000000000001000000000000000000,
	Tofu: 0b00000000000000100000000000000000
};

export const OTHER_ALLERGENS = {
	Peanuts: 0b00000000000000010000000000000000,
	Treenuts: 0b00000000000000001000000000000000,
	Dairy: 0b00000000000000000100000000000000,
	Cheese: 0b00000000000000000010000000000000,
	Eggs: 0b00000000000000000001000000000000,
	Wheat: 0b00000000000000000000100000000000,
	Gluten: 0b00000000000000000000010000000000,
	Soy: 0b00000000000000000000001000000000,
	Sesame: 0b00000000000000000000000100000000,
	Fruit: 0b00000000000000000000000010000000,
	Shellfish: 0b00000000000000000000000001000000,
	Corn: 0b00000000000000000000000000100000,
	Honey: 0b00000000000000000000000000010000,
	'Spicy Food': 0b00000000000000000000000000001000,
	Mushrooms: 0b00000000000000000000000000000100,
	Onions: 0b00000000000000000000000000000010,
	Legumes: 0b00000000000000000000000000000001,
	'High Sugar': 0b00010000000000000000000000000000,
	Garlic: 0b00001000000000000000000000000000,
	Nightshades: 0b00000100000000000000000000000000,
	Potatoes: 0b00000010000000000000000000000000,
	'High Carbs': 0b00100000000000000000000000000000,
	'High Fodmap Foods': 0b01000000000000000000000000000000
};

export const PRESET_DIETS = {
	PALEO: 0b00010011000000110110111000100001,
	VEGETARIAN: 0b00000000111111000000000001000000,
	VEGAN: 0b00000000111111000111000001010000,
	'GLUTEN-FREE': 0b00000000000000000000110000000000,
	FODMAP: 0b01000000000000000000000000000000,
	KETO: 0b00110000000000000000000000000000
};

export const IMAGE_MAP = {
	PALEO: 'images/diets/paleo_diet.svg',
	VEGETARIAN: 'images/diets/vegetarian_diet.svg',
	VEGAN: 'images/diets/vegan_diet.svg',
	'GLUTEN-FREE': 'images/diets/gf_diet.svg',
	FODMAP: 'images/diets/fodmap_diet.svg',
	KETO: 'images/diets/keto_diet.svg'
};

export const BINARY = [
	'High Fodmap',
	'High Carbs',
	'High Sugar',
	'Garlic',
	'Nightshades',
	'Potatoes',
	'Rice',
	'Beef',
	'Red Meat',
	'White Meat',
	'Pork',
	'Fish',
	'Other Seafood',
	'Tofu',
	'Peanuts',
	'Treenuts',
	'Dairy',
	'Cheese',
	'Eggs',
	'Wheat',
	'Gluten',
	'Soy',
	'Sesame Seeds',
	'Fruit',
	'Shellfish',
	'Corn',
	'Honey',
	'Spicy Food',
	'Mushrooms',
	'Onions', 
	'Legumes' 
];

export const DESSERTS = {
	dessert: 1,
	desserts: 1,
	ducle: 1,
	dolce: 1,
	sweet: 1,
	sweets: 1,
	cake: 1,
	cakes: 1
};

export const ANALYTICS_ALLERGEN_MAP = {
	Beef: 'Beef',
	'Red Meat': 'RedMeat',
	'White Meat': 'WhiteMeat',
	Pork: 'Pork',
	Fish: 'Fish',
	'Other Seafood': 'OtherSeafood',
	Tofu: 'Tofu',
	Peanuts: 'Peanuts',
	Treenuts: 'Treenuts',
	Dairy: 'Dairy',
	Cheese: 'Cheese',
	Eggs: 'Eggs',
	Wheat: 'Wheat',
	Gluten: 'Gluten',
	Soy: 'Soy',
	Sesame: 'Sesame',
	Fruit: 'Fruit',
	Shellfish: 'Shellfish',
	Corn: 'Corn',
	Honey: 'Honey',
	'Spicy Food': 'Spicy',
	Mushrooms: 'Mushrooms',
	Onions: 'Onions',
	Legumes: 'Legumes',
	'High Sugar': 'HighSugar',
	Garlic: 'Garlic',
	Nightshades: 'Nightshades',
	Potatoes: 'Potatoes'
};

export const ANALYTICS_DIET_MAP = {
	PALEO: 'Paleo',
	VEGAN: 'Vegan',
	VEGETARIAN: 'Vegetarian',
	'GLUTEN-FREE': 'Gluten-Free',
	FODMAP: 'Fodmap',
	KETO: 'Keto'
};

export const LOW_PRIORITY_MENUS = {
	special: 1,
	kids: 1,
	brunch: 1,
	sauce: 1,
	sauces: 1,
	'kids menu': 1
};

export const DIET_TAG_NAMES = [
	{
		name: '100% Percent Gluten Free',
		color: '#DD7417',
		description: 'No gluten is on-premises. This restaurant is 100% gluten-free. Celiac patrons are welcome here.'
	},
	{
		name: 'Peanut Allergy Friendly',
		color: '#c7a1dd',
		description:
			'Customers with peanut allergies are welcome here. Please notify the restaurant staff of your allergy/allergies upon placing an order in-person, over the phone, or online.'
	},
	{
		name: 'Shellfish Allergy Friendly',
		color: '#549621',
		description:
			'Customers with shellfish allergies are welcome here. Please notify the restaurant staff of your allergy/allergies upon placing an order in-person, over the phone, or online.'
	},
	{
		name: 'Tree Nut Allergy Friendly',
		color: '#F2A156',
		description:
			'Customers with Tree Nut allergies are welcome here. Please notify the restaurant staff of your allergy/allergies upon placing an order in-person, over the phone, or online.'
	},
	{
		name: 'Dairy Allergy Friendly',
		color: '#676D63',
		description:
			'Customers with dairy allergies are welcome here. Please notify the restaurant staff of your allergy/allergies upon placing an order in-person, over the phone, or online.'
	},
	{
		name: 'Egg Allergy Friendly',
		color: '#060730',
		description:
			'Customers with egg allergies are welcome here. Please notify the restaurant staff of your allergy/allergies upon placing an order in-person, over the phone, or online.'
	},
	{
		name: 'Sesame Allergy Friendly',
		color: '#967C21',
		description:
			'Customers with sesame allergies are welcome here. Please notify the restaurant staff of your allergy/allergies upon placing an order in-person, over the phone, or online.'
	},
	{
		name: 'Soy Allergy Friendly',
		color: '#7A1111',
		description:
			'Customers with soy allergies are welcome here. Please notify the restaurant staff of your allergy/allergies upon placing an order in-person, over the phone, or online.'
	},
	{
		name: 'Dedicated Gluten-Free Food Prep',
		color: '#DD7417',
		description:
			'Steps are taken to establish a dedicated gluten-free preparation method for gluten-free dishes. Please inquire with restaurant staff for more details. Celiac patrons are welcome here, however, please notify restaurant staff of your specific dietary needs upon placing an order in-person, over the phone, or online.'
	},
	{
		name: 'Gluten Friendly Options',
		color: '#DD7417',
		description:
			'Gluten-friendly or “gluten-free” options are available at this restaurant. Please note that this restaurant does not have a 100% gluten-free facility or dedicated methods to mitigate cross-contamination. Celiac patrons are not recommended to eat here. Please notify restaurant staff of your specific dietary needs upon placing an order in-person, over the phone, or online.'
	},
	{
		name: '100% Vegan',
		color: '#5BCA89',
		description: 'Fully plant-based restaurant. Vegans rejoice!'
	},
	{
		name: 'Allergy Friendly',
		color: '#0485A1',
		description:
			'Customers with varying allergies are welcome here but menu item suitability is not guaranteed. To ensure this restaurant is suitable for you, please notify the restaurant staff of your allergy/allergies before placing an order in-person, over the phone, or online.'
	},
	{
		name: 'Low Carb Options',
		color: '#BA0C0C',
		description: 'This establishment has low carb options'
	},
	{
		name: 'Vegan Options',
		color: '#5BCA89',
		description: 'Plant-based menu items and/or modifications are available at this restaurant.'
	},
	{
		name: '100% Vegetarian',
		color: '#5BCA89',
		description: 'Fully vegetarian restaurant'
	},
	{
		name: 'Kosher',
		color: '#216596',
		description: 'This restaurant facility and the food served here is certified Kosher by the local authority.'
	},
	{
		name: 'Halal',
		color: '#219653',
		description: 'This food served at this restaurant is certified Halal by a local authority.'
	}
];

export const NUTRITION_COLOR = {
	carbs: 'red',
	fat: 'blue',
	protein: 'green'
};

export const ALLERGEN_INFORMATION = {
	'High Fodmap': {
		description:
			'FODMAPS are short-chain carbs that are common in many foods including but not limited to onions, peaches, beans, lentils, dairy products (lactose), sweeteners, breads, and many vegetables.',
		image: highfodmap
	},
	'High Carbs': {
		description:
			'These foods are high in carbs, and may be loaded with sugar and starch. These should not be eaten on a Ketogenic diet.',
		image: highketo
	},
	'High Sugar': {
		description:
			'Foods high in the very common sweet, soluble carbohydrates called sugar. Donuts, chocolate chip cookies, and strawberry jam are just a few examples of what we consider high in sugar.',
		image: highsugar
	},
	Garlic: {
		description:
			'Related to the onion family, this powerful, irresistible, nutrient packed ingredient has been used in food and medicine for thousands of years.',
		image: garlic
	},
	Nightshades: {
		description:
			'A group of fruits and vegetables that include but are not limited to eggplants, tomatoes, bell peppers, and potatoes. They may cause inflammatory reactions for some people with intolerances.',
		image: nightshades
	},
	Potatoes: {
		description:
			'Energy-packed with more potassium than a banana - but most importantly, the main ingredient for fries! Includes both white and sweet potatoes.',
		image: potato
	},
	Rice: {
		description:
			'The most widely consumed staple in the world, rice can come in many forms and is found in a variety of dishes, especially in Asian cuisine.',
		image: rice
	},
	Beef: {
		description: 'Culinary name for meat from cattle (cows). Major source of protein',
		image: beef
	},
	'Red Meat': {
		description:
			'Red meats (excluding beef) include: lamb, mutton, veal, venison, goat, and other meats that are commonly red in colour when raw.',
		image: redmeat
	},
	'White Meat': {
		description: 'Light in colour meat such as chicken, turkey, and other poultry.',
		image: whitemeat
	},
	Pork: {
		description: 'Culinary name for meat from pigs. Also includes pork derivatives such as lard and gelatin',
		image: pork
	},
	Fish: {
		description:
			'Salmon, tuna, trout, halibut, snapper, sea bass, and more. An important source of protein and nutrients. This does not include shellfish.',
		image: fish
	},
	'Other Seafood': {
		description: 'All seafood (except fish) including shellfish, molluscs, crustaceans, and other aquatic animals.',
		image: seafood
	},
	Tofu: {
		description: 'A food made from condensed soy milk - commonly prepared as a vegan or vegetarian alternative.',
		image: tofu
	},
	Peanuts: {
		description:
			'Plentiful in vitamins and minerals, peanuts are legumes that grow underground. Note, peanuts are not actually “nuts”.',
		image: peanut
	},
	Treenuts: {
		description:
			'Treenuts include but are not limited to walnuts, almonds, cashews, pecans, pistachios, Brazil nuts, hazelnuts, shea nuts, chestnuts, and macadamia nuts.',
		image: treenuts
	},
	Dairy: {
		description: 'Dairy products are produced by the milk of mammals, primarily from cattle, goats, and sheep.',
		image: dairy
	},
	Cheese: {
		description:
			'Cheese is a dairy product made from the milk mammals, primarily from cattle, goats, and sheep. It is composed of proteins and fats.',
		image: cheese
	},
	Eggs: {
		description:
			'Packed with nutrients, the most common eggs are chicken eggs. Eggs are widely used in cooking and can be found as an ingredient in many food items.',
		image: eggs
	},
	Wheat: {
		description:
			'Pasta, bread, cake, cookies - a lot of your favourite foods are made from wheat, which is a cereal grain that feeds the world.',
		image: wheat
	},
	Gluten: {
		description:
			'Gluten is a hidden allergen and the general name for the proteins found mostly in wheat, barley and rye products',
		image: gluten
	},
	Soy: {
		description:
			'Soy, derived from the soybean, has numerous uses in food. Soy is used in tofu, soy milk, and many meat and dairy alternative foods.',
		image: soy
	},
	'Sesame Seeds': {
		description:
			'A common ingredient with a nutty flavour, sesame is used in dishes such as goma-ae, tahini, halva, breads, and more.',
		image: sesame
	},
	Fruit: {
		description:
			'From acai berries to mangos to bananas, fruits are nutritious and part of a healthy, balanced diet.',
		image: fruit
	},
	Shellfish: {
		description:
			'Seafood that Includes but not limited to molluscs, crustaceans, and echinoderms. A common food allergen.',
		image: shellfish
	},
	Corn: {
		description:
			'Also known as maize, corn is one of the most popular foods in the world. Find it on a cob, mixed with rice, in burritos, and in many other dishes.',
		image: corn
	},
	Honey: {
		description: 'The sweet, sticky nectar made by bees, and a common alternative to refined sugar.',
		image: honey
	},
	'Spicy Food': {
		description:
			'Spicy food includes any food that would be classified above “mild”. That means no chili peppers, jalapenos, tabasco sauces, and generally, nothing considered “hot”.',
		image: spicyfood
	},
	Mushrooms: {
		description:
			'A fungus produced above ground, including the most common species as well as truffles. We are not responsible if some of these are magical.',
		image: mushrooms
	},
	Onions: {
		description:
			'Including but not limited to red onions, white onions, green onions, leeks, shallots, and chives. Commonly known to elicit tears.',
		image: onion
	},
	Legumes: {
		description:
			'High in fibre and protein, legumes include but are not limited to beans, peas, lentils, and chickpeas. Commonly a major component of vegan and vegetarian diets.',
		image: legumes
	}
};
