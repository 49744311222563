import React, { useCallback, useMemo } from 'react';

const Modal = ({
    className,
    children,
    actived,
    setActived,
    onConfirm,
    controller,
    top,
    bottom,
    style,
    contentRef,
    justifyContent,
    backgroundColor,
    handlers = {},
    bgStyle,
    ...rest
}) => {
    const handleOnToggle = useCallback(
        () => {
            setActived((state) => !state);
        },
        [ setActived ]
    );
    const memoTop = useMemo(
        () => {
            if (top) {
                return { paddingTop: `${top}`, alignItems: 'flex-start' };
            } else if (bottom) {
                return { paddingBottom: `${bottom}`, alignItems: 'flex-end' };
            } else {
                return {};
            }
        },
        [ top, bottom ]
    );

    const memoJustifyContent = useMemo(
        () => {
            if (justifyContent) {
                return { justifyContent: `${justifyContent}` };
            } else {
                return {};
            }
        },
        [ justifyContent ]
    );
    const renderChildren = useMemo(
        () => {
            if (typeof children === 'function') {
                return children({ onConfirm, controller, ...rest });
            } else if (typeof children === 'object') {
                return children;
            } else {
                return '';
            }
        },
        [ children, onConfirm, controller, rest ]
    );

    return (
        <div
            className={[ 'modalP', className ].join(' ')}
            data-actived={actived}
            style={{
                ...memoJustifyContent,
                ...memoTop,
                ...style
            }}
            {...rest}>
            <div
                ref={contentRef}
                {...handlers}
                className={[ 'content' ].join('  ')}>
                {renderChildren}
            </div>
            <div
                className='bg'
                style={{ backgroundColor: backgroundColor, ...bgStyle }}
                onClick={handleOnToggle}
            />
        </div>
    );
};

export default Modal;
