import React, { Component } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';

import './styles/main.scss';

import { GET_RESTAURANT_INFO_URL, STATUS_OK } from './Config/config';
import Hero from './Components/Hero';
import MenuSection from './Components/MenuSection';
import Ad from './Components/Ad';
import Footer from './Components/Footer';

const Tracker = require('honey-tracker');
const TRACKER_CODES = require('./Config/encodings');

class Widget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeItem: null,
			restaurant: null,
			isTag: false,
			tagName: '',
			tagDescription: '',
			tagIcon: null,
			visited: false,
			loading: true,
			empty: false
		};
		ReactGA.initialize('UA-97115818-2');
		this.tracker = new Tracker();
		this.tracker.run();
	}

	/**
     * From the URL, parse it into 3 possible formats that we might see
     * 1) /restaurant-url
     * 2) /?restaurant-url
     * 3) /restaurant-url=&.....
     */
	parseURL = () => {
		let url;
		try {
			url = window.location.href.split('/');
			url = url[url.length - 1];
			url = url.split('=')[0];
			if(url[0] == '?'){
				       url = url.slice(1);  
				   }
			if(url.includes("?")){
			   url = url.split("?")[0];
			}
			if(url.includes("&")){
				       url = url.split("&")[0];
				   }
			return url;
		    }
	     catch (e){
			return ''
		}
	}

	clickButton = (id) => {
		this.tracker.trackEvent(TRACKER_CODES.sources.WEB_SOURCE, TRACKER_CODES.actions.MENU_VIEW_EVENT, {
			restaurant_id: id
		});
	};

	clickItem = (item_id) => {
		ReactGA.event({
			category: this.state.restaurant.name,
			action: 'Item Clicked',
			label: item_id
		});
		this.tracker.trackEvent(TRACKER_CODES.sources.WEB_SOURCE, TRACKER_CODES.actions.ITEM_CLICK_EVENT, {
			restaurant_id: this.state.restaurant.id,
			data: { item_id: item_id }
		});
	};

	clickOrder = (id) => {
		this.tracker.trackEvent(TRACKER_CODES.sources.WEB_SOURCE, TRACKER_CODES.actions.ORDER_CLICK_EVENT, {
			restaurant_id: id
		});
	};

	clickInfo = (id) => {
		this.tracker.trackEvent(TRACKER_CODES.sources.WEB_SOURCE, TRACKER_CODES.actions.INFO_CLICK_EVENT, {
			restaurant_id: id
		});
	};

	clickAllergen = (allergen) => {
		ReactGA.event({
			category: this.state.restaurant.name,
			action: 'Allergen Clicked',
			label: allergen
		});
		this.tracker.trackEvent(TRACKER_CODES.sources.WEB_SOURCE, TRACKER_CODES.actions.ALLERGEN_CLICK_EVENT, {
			restaurant_id: this.state.restaurant.id,
			data: allergen
		});
	};

	clickDiet = (diet) => {
		ReactGA.event({
			category: this.state.restaurant.name,
			action: 'Diet Clicked',
			label: diet
		});
		this.tracker.trackEvent(TRACKER_CODES.sources.WEB_SOURCE, TRACKER_CODES.actions.DIET_CLICK_EVENT, {
			restaurant_id: this.state.restaurant.id,
			data: diet
		});
	};

	async fetchRestaurant(url) {
		if (!url || url == '') {
			this.setState({ empty: true, loading: false });
		}
		try {
			let res = await axios.get(GET_RESTAURANT_INFO_URL(url));
			if (res && res.status == STATUS_OK) {
				this.clickButton(res.data.id);
				await this.setState({ restaurant: res.data });
				await this.setState({ loading: false });
			}
		} catch (error) {}
	}

	async componentDidMount() {
		let url = this.parseURL();
		ReactGA.pageview(url);
		await this.fetchRestaurant(url);
	}

	get hero() {
		let hero;
		hero = this.state.restaurant ? (
			<Hero
				name={this.state.restaurant.name}
				isVerified={this.state.restaurant.verified}
				dietaryPolicy={this.state.restaurant.dietaryPolicy}
				image={this.state.restaurant.image}
				tag={this.state.restaurant.dietTagVector}
			/>
		) : null;
		return hero;
	}

	get menuSection() {
		let menuSection;
		menuSection = this.state.restaurant ? (
			<>
				<MenuSection
					items={this.state.restaurant.items}
					clickAllergen={this.clickAllergen}
					clickDiet={this.clickDiet}
					clickItem={this.clickItem}
					customButtonNames={
						this.state.restaurant.metaInfo ? this.state.restaurant.metaInfo.customButtonNames : false
					}
					disabledButtonNames={
						this.state.restaurant.metaInfo ? this.state.restaurant.metaInfo.disabledButtonNames : false
					}
					disableContains={this.state.restaurant.metaInfo ? !this.state.restaurant.metaInfo.showContains : false}
					disableIcons={this.state.restaurant.metaInfo ? !this.state.restaurant.metaInfo.showIcons : false}
					disablePrice={
						this.state.restaurant.metaInfo && this.state.restaurant.metaInfo.showPrice ? (
							!this.state.restaurant.metaInfo.showPrice
						) : (
							false
						)
					}
					menuSubheadings={this.state.restaurant.menuSubheadings}
					restaurantOrder={this.state.restaurant.onlineOrder}
					menuOrderings={this.state.restaurant.menuOrderings}
					sectionSubheadings={this.state.restaurant.sectionSubheadings}
					sectionOrderings={this.state.restaurant.sectionOrderings}
				/>
				{this.state.restaurant.metaInfo && this.state.restaurant.metaInfo.showAd && <Ad/>}
			</>
		) : null;
		return menuSection;
	}

	get metaInfo() {
		return <div className="metaInfo" />;
	}

	render() {
		if (this.state.loading) return <div className="loader" />;
		if (this.state.empty) {
			window.location.href="https://honeycomb.ai/food"
			return;
		}
		return [ this.metaInfo, this.modal, this.hero, this.menuSection, <Footer /> ];
	}
}

export default Widget;
