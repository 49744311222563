import React from 'react';

const MenuChooser = (props) => {
    const menus = props.menus;

    return (
        <div className='menu-chooser-wrapper fade'>
            {menus.map((menu, index) => (
                <div
                    value={menu}
                    key={index}
                    onClick={() => props.selectActiveMenu(menu)}>
                    {menu}
                </div>
            ))}
        </div>
    );
};

export default MenuChooser;
