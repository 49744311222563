import React, { Component } from 'react';
import {
    ANALYTICS_ALLERGEN_MAP,
    ANALYTICS_DIET_MAP,
    IMAGE_MAP,
    PRESET_DIETS,
    PROTEINS,
    OTHER_ALLERGENS,
    DISCLAIMER_PROFILER
} from '../Config/config';

import VisibilitySensor from 'react-visibility-sensor';

class Profile extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showModal: false,
            PALEO: false,
            VEGETARIAN: false,
            VEGAN: false,
            'GLUTEN-FREE': false,
            FODMAP: false,
            KETO: false,
            agreedTerms: true,
            profileInView: true
        };
    }

    toggleModal = () => {
        this.props.toggleMenuVisibility();
        this.setState({ showModal: !this.state.showModal });
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleProfileViewChange = (isVisible) => {
        this.setState({ profileInView: isVisible });
    };

    verifyDone = () => {
        if (!this.state.agreedTerms) {
            return;
        }
        this.toggleModal();
    };

    disableButton = (key) => {
        if (
            this.props.disabledButtonNames &&
            this.props.disabledButtonNames[key]
        ) {
            return true;
        }
        return false;
    };

    resetDiets = () => {
        this.props.clearBinary();
        this.setState({
            PALEO: false,
            VEGETARIAN: false,
            VEGAN: false,
            'GLUTEN-FREE': false,
            FODMAP: false,
            KETO: false
        });
    };

    getDietOrAllergenName (key) {
        if (this.props.customButtonNames && this.props.customButtonNames[key]) {
            return this.props.customButtonNames[key];
        }
        return key;
    }

    getAllergens (base) {
        let allergens = [];
        let allergenKeys = Object.keys(base);
        for (let i = 0; i < allergenKeys.length; i++) {
            if (this.disableButton(allergenKeys[i])) continue;
            let currentAllergen;
            if ((base[allergenKeys[i]] & this.props.binary) != 0) {
                currentAllergen = (
                    <div
                        className='allergenbutton-active'
                        onClick={() => {
                            this.props.toggleBinary(base[allergenKeys[i]]);
                        }}>
                        {this.getDietOrAllergenName(allergenKeys[i])}
                    </div>
                );
            } else {
                currentAllergen = (
                    <div
                        className='allergenbutton'
                        onClick={() => {
                            this.props.clickAllergen(
                                ANALYTICS_ALLERGEN_MAP[allergenKeys[i]]
                            );
                            this.props.toggleBinary(base[allergenKeys[i]]);
                        }}>
                        {this.getDietOrAllergenName(allergenKeys[i])}
                    </div>
                );
            }
            allergens.push(currentAllergen);
        }
        return allergens;
    }

    handleDietDeselect = (binary) => {
        //If Vegetarian is deselected and Vegan is already selected, do nothing to the binary
        if (binary == PRESET_DIETS.VEGETARIAN) {
            if (
                (this.props.binary & PRESET_DIETS.VEGAN) ==
                PRESET_DIETS.VEGAN
            ) {
                return;
            }
        }
        if (binary == PRESET_DIETS.VEGAN) {
            if (this.state.VEGETARIAN) {
                let newBinary = this.props.binary & ~PRESET_DIETS.VEGAN;
                newBinary = newBinary | PRESET_DIETS.VEGETARIAN;
                this.props.setBinary(newBinary);
                return;
            }
            if (this.state.PALEO) {
                let newBinary = this.props.binary & ~PRESET_DIETS.VEGAN;
                newBinary = newBinary | PRESET_DIETS.PALEO;
                this.props.setBinary(newBinary);
                return;
            }
        }
        if (binary == PRESET_DIETS.PALEO) {
            if (this.state['GLUTEN-FREE']) {
                let newBinary = this.props.binary & ~PRESET_DIETS.PALEO;
                newBinary = newBinary | PRESET_DIETS['GLUTEN-FREE'];
                this.props.setBinary(newBinary);
                return;
            }
            if (this.state.KETO) {
                let newBinary = this.props.binary & ~PRESET_DIETS.PALEO;
                newBinary = newBinary | PRESET_DIETS.KETO;
                this.props.setBinary(newBinary);
                return;
            }
        }
        if (binary == PRESET_DIETS.KETO) {
            if (this.state.PALEO) {
                return;
            }
        }
        this.props.toggleBinary(binary);
    };

    handleDietSelect = (binary) => {
        this.props.setBinary(this.props.binary|binary);
    }
            
    getDiets (base) {
        let diets = [];
        let dietKeys = Object.keys(base);

        for (let i = 0; i < dietKeys.length; i++) {
            let currentDiet;
            if (this.disableButton(dietKeys[i])) continue;
            if (this.state[dietKeys[i]]) {
                currentDiet = (
                    <div
                        data-name={this.getDietOrAllergenName(dietKeys[i])}
                        className='dietbutton active'
                        onClick={() => {
                            this.handleDietDeselect(base[dietKeys[i]]);
                            this.setState({ [dietKeys[i]]: false });
                        }}>
                        <img
                            src={IMAGE_MAP[dietKeys[i]]}
                            width='32'
                            className='dietimg'
                        />
                        <div className='diettext dt3'>
                            {this.getDietOrAllergenName(dietKeys[i])}
                        </div>
                    </div>
                );
            } else {
                currentDiet = (
                    <div
                        className='dietbutton'
                        onClick={() => {
                            this.props.clickDiet(
                                ANALYTICS_DIET_MAP[dietKeys[i]]
                            );
                            this.handleDietSelect(base[dietKeys[i]]);
                            this.setState({ [dietKeys[i]]: true });
                        }}>
                        <img
                            src={IMAGE_MAP[dietKeys[i]]}
                            width='32'
                            className='dietimg'
                        />
                        <div className='diettext dt3'>
                            {this.getDietOrAllergenName(dietKeys[i])}
                        </div>
                    </div>
                );
            }
            diets.push(currentDiet);
        }
        let dietWrapper = <div className='dietbutton-wrapper'>{diets}</div>;
        return dietWrapper;
    }

    get proteins () {
        return this.getAllergens(PROTEINS);
    }

    get otherAllergens () {
        return this.getAllergens(OTHER_ALLERGENS);
    }

    get presetDiets () {
        return this.getDiets(PRESET_DIETS);
    }

    render () {
        return (
            <div className='w-container'>
                <div
                    className='chooseprofile'
                    data-ix='new-interaction-show-fab'>
                    <div className='select-diet w-hidden-main w-hidden-medium w-hidden-small w-hidden-tiny'>
                        Select a diet
                    </div>
                    <div id='selectdiet' className='div-block'>
                        {!this.state.showModal && (
                            <VisibilitySensor
                                onChange={this.handleProfileViewChange}>
                                <a className='w-inline-block'>
                                    <div
                                        data-ix='show-and-hide'
                                        className='newbutton filter'
                                        onClick={this.verifyDone}>
                                        <img
                                            id='filter-button-icon'
                                            src='images/filter1.png'
                                            width='19'
                                            className='dietimg dti2'
                                        />
                                        <div className='diettext dt2'>
                                            filter by diet
                                        </div>
                                    </div>
                                </a>
                            </VisibilitySensor>
                        )}
                    </div>
                    {this.state.showModal ? (
                        <div className='accordionwrapper fade'>
                            <div className='allergens w-clearfix'>
                                <div
                                    className='step-text accordion-header'
                                    data-num='1.'>
                                    Choose Your Diets
                                </div>

                                <div className='select-diet-div w-clearfix'>
                                    {this.presetDiets}
                                </div>
                                <div
                                    className='step-text accordion-header'
                                    data-num='2.'>
                                    Select Ingredients to Avoid
                                </div>
                                <div className='w-row'>
                                    <div className='w-col w-col-11'>
                                        <div
                                            className='substep-text'
                                            id='protein-text'>
                                            Proteins
                                        </div>
                                        <div className='allergenbutton-wrapper'>
                                            {this.proteins}
                                        </div>
                                    </div>
                                </div>

                                <div className='w-row'>
                                    <div className='w-col w-col-11'>
                                        <div className='substep-text'>
                                            Other Ingredients
                                        </div>
                                        <div className='allergenbutton-wrapper'>
                                            {this.otherAllergens}
                                        </div>
                                    </div>
                                </div>
                                <div className='w-row'>
                                    <div className='w-col w-col-12'>
                                        <div className='allergy-warning-text'>
                                            IMPORTANT: If you have a{' '}
                                            <span>SEVERE</span> allergy, please
                                            read the allergen information at the
                                            top of this page to see if this
                                            restaurant is suitable for you.
                                        </div>
                                        <span className='disclaimer-text'>
                                            {DISCLAIMER_PROFILER}
                                            <span
                                                onClick={() =>
                                                    window.open(
                                                        'https://www.honeycomb.ai/terms-of-use',
                                                        '_blank'
                                                    )}
                                                target='_blank'
                                                className='terms-of-service-text'>
                                                Terms of Service and Disclaimer
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className='w-row profile-footer-wrapper'>
                                    <div
                                        className='filter newbutton'
                                        onClick={this.verifyDone}>
                                        DONE
                                    </div>
                                    <div
                                        id='clear-preferences'
                                        className='hidetext'
                                        onClick={this.resetDiets}>
                                        Reset Preferences
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                {!this.state.profileInView && !this.state.showModal ? (
                    <a
                        href='#selectdiet'
                        className='link-block-2 w-inline-block'
                        data-ix='hide-fab'
                        onClick={() => this.verifyDone()}>
                        <div
                            data-ix='show-and-hide'
                            className='newbutton filter floater'>
                            <img
                                src='images/filter1.png'
                                width='25'
                                className='dietimg dti2'
                            />
                        </div>
                    </a>
                ) : null}
            </div>
        );
    }
}

export default Profile;
