import React, { Component } from 'react';

import Header from './Header';
import { GenericModal } from './GenericModal';

import { dietTagName, IMAGE_URL } from '../Config/config';

import { DIET_TAG_NAMES } from '../Config/config';
import Modal from './Modal';

//Render 6 tags if not mobile and 3 if mobile
const INITIAL_MAX_TAGS = window.innerWidth > 769 ? 6 : 3;

const MAX_DIET_TAGS = 17;

const GF_INDICES = new Set([ 0, 8, 9 ]);
const VEGAN_INDICES = new Set([ 10, 13 ]);

class Hero extends Component {
    constructor (props) {
        super(props);
        this.state = {
            maxShowableTags: INITIAL_MAX_TAGS,
            activeModalTitle: '',
            activeModalDescription: '',
            shouldRenderModal: false
        };
    }

    toggleModal = (title, description) => {
        if (this.state.shouldRenderModal) {
            this.setState({
                shouldRenderModal: false,
                activeModalDescription: '',
                activeModalTitle: ''
            });
        } else
            this.setState({
                shouldRenderModal: true,
                activeModalTitle: title,
                activeModalDescription: description
            });
    };

    /**
     * Create the name, description and icon of a diet tag
     * @param diet : diet object containing name, description, image
     */
    getDietTagRow = (diet) => {
        return (
            <div
                className='diet-wrapper'
                data-color={diet.color}
                key={diet.name}
                onClick={() => this.toggleModal(diet.name, diet.description)}>
                <span className='diet-tag-text' style={{ color: diet.color }}>
                    {diet.name}
                </span>
                <span className='diet-tag-description'>{diet.description}</span>
            </div>
        );
    };

    renderShowMoreDietTags = (pushedTags) => {
        if (pushedTags <= INITIAL_MAX_TAGS) return null;
        return (
            <div className='diet-tag-wrapper-more-button'>
                <span>
                    {this.state.maxShowableTags === INITIAL_MAX_TAGS ? (
                        'EXPAND INFO'
                    ) : (
                        'COLLAPSE INFO'
                    )}
                </span>
                <button
                    onClick={() => {
                        this.setState((state) => {
                            return {
                                maxShowableTags:
                                    state.maxShowableTags === INITIAL_MAX_TAGS
                                        ? MAX_DIET_TAGS
                                        : INITIAL_MAX_TAGS
                            };
                        });
                    }}>
                    {this.state.maxShowableTags === INITIAL_MAX_TAGS ? (
                        '↓'
                    ) : (
                        '↑'
                    )}
                </button>
            </div>
        );
    };

    renderDietaryPolicy () {
        return (
            <div
                className='diet-wrapper'
                key={'r-dietaryPolicy'}
                onClick={() =>
                    this.toggleModal(
                        'Dietary Policy',
                        this.props.dietaryPolicy
                    )}>
                <div className='diet-tag-text primary'>Dietary Policy</div>
                <div className='diet-tag-description'>
                    {this.props.dietaryPolicy}
                </div>
            </div>
        );
    }

    /**
     * Render the diet tag icons corresponding to restaurant diet tag.
     * Show only one tag per group. e.g the GF tags are 1 group, the Vegan + Veggie are another
     */
    renderDietTags = () => {
        if (!this.props.tag) return null;
        let pushedTags = 0;
        let dietTags = [];
        let veganSet = false;
        let gfSet = false;

        if (this.props.dietaryPolicy) {
            pushedTags++;
            dietTags.push(this.renderDietaryPolicy());
        }

        for (let i = 0; i < MAX_DIET_TAGS; i++) {
            if (((1 << i) & this.props.tag) > 0) {
                if (GF_INDICES.has(i)) {
                    if (!gfSet) {
                        if (pushedTags < this.state.maxShowableTags)
                            dietTags.push(
                                this.getDietTagRow(DIET_TAG_NAMES[i])
                            );
                        pushedTags++;
                        gfSet = true;
                    }
                } else if (VEGAN_INDICES.has(i)) {
                    if (!veganSet) {
                        if (pushedTags < this.state.maxShowableTags)
                            dietTags.push(
                                this.getDietTagRow(DIET_TAG_NAMES[i])
                            );
                        pushedTags++;
                        veganSet = true;
                    }
                } else {
                    if (pushedTags < this.state.maxShowableTags)
                        dietTags.push(this.getDietTagRow(DIET_TAG_NAMES[i]));
                    pushedTags++;
                }
            }
        }
        return (
            <div className='diet-tag-container' id='diet-tag-wrapper'>
                {dietTags}
                {this.renderShowMoreDietTags(pushedTags)}
            </div>
        );
    };

    render () {
        return (
            <div className='w-container'>
                <Header
                    name={this.props.name}
                    image={this.props.image}
                    isVerified={this.props.isVerified}
                />
                {this.renderDietTags()}
                {this.state.shouldRenderModal && (
                    <Modal
                        className='generic-modal modalP fade'
                        actived={this.state.shouldRenderModal}
                        setActived={this.toggleModal}>
                        <span
                            className='modal-close-button modal-close-button-black'
                            onClick={this.toggleModal}>
                            &times;
                        </span>
                        <GenericModal
                            name={this.state.activeModalTitle}
                            description={this.state.activeModalDescription}
                        />
                    </Modal>
                )}
            </div>
        );
    }
}

export default Hero;
