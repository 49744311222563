import React from 'react';
import { DISCLAIMER, IMAGE_URL, DISCLAIMER_UNVERIFIED } from '../Config/config';
import checkMark from '../assets/images/checkmark.svg';

/**
 * Header stateless component wrapping restaurant image, name and disclaimer
 * @param name
 * @param image 
 */

const PoweredByLogo = () => {
    return (
        <div id='powered-desk' className='poweredtext'>
            Powered by
            <span>
                <a
                    href='http://eat.app.link/web'
                    target='_blank'
                    className='honeylink'>
                    <img className='logo' src='images/logo-black.png' />
                </a>
            </span>
        </div>
    );
};
const Header = ({ name, image, isVerified }) => {
    return (
        <div className='w-container' id='header-container'>
            <PoweredByLogo />

            <img className='restaurant-title-image' src={IMAGE_URL(image)} />

            <div className='header-text-wrapper'>
                <div id='restaurant-title-header'>
                    <div id='restaurant-title'>{name}</div>
                </div>
                {isVerified ? (
                    <div className='verified-wrapper'>
                        <span className='verified-subtitle'>Verified Menu</span>
                        <img id='verified-checkmark' src={checkMark} />
                    </div>
                ) : (
                    <div className='verified-wrapper'>
                        <span className='verified-subtitle'>
                            Unverified Menu
                        </span>
                    </div>
                )}
                <span className='disclaimer-text'>
                    {isVerified ? DISCLAIMER : DISCLAIMER_UNVERIFIED}
                    <span
                        onClick={() =>
                            window.open(
                                'https://www.honeycomb.ai/terms-of-use',
                                '_blank'
                            )}
                        target='_blank'
                        className='terms-of-service-text'>
                        Terms of Service and Disclaimer
                    </span>
                </span>
            </div>
        </div>
    );
};

export default Header;
