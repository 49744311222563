import React, { Component } from 'react';
import Item from './Item';
import MenuChooser from './MenuChooser';

class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			binary: 0,
			activeMenu: null,
			shouldRenderDropdown: false
		};
		this.menus = {};
	}

	sortMenus = (menus) => {
		let that = this;
		menus.sort(function(a, b) {
			let aMenuValue = that.props.menuOrderings && that.props.menuOrderings[a] ? that.props.menuOrderings[a] : 0;
			let bMenuValue = that.props.menuOrderings && that.props.menuOrderings[b] ? that.props.menuOrderings[b] : 0;
			if (aMenuValue > bMenuValue) return 1;
			else if (bMenuValue > aMenuValue) return -1;
			else return 0;
		});
		return menus;
	};

	get noOptionsAvailable() {
		return (
			<div className="w-container">
				<div className="benefits-wrapper">
					<div className="benefit-div">
						<div className="benefit-title">No suitable options!</div>
						<div className="benefit-sub">
							No suitable options on this menu for your diet. Select another menu if available!
						</div>
					</div>
				</div>
			</div>
		);
	}

	toggleRenderDropdown = () => {
		this.setState({
			shouldRenderDropdown: !this.state.shouldRenderDropdown
		});
	};

	selectMenu = (menu) => {
		this.setState({ activeMenu: menu, shouldRenderDropdown: false });
	};

	renderSectionHeading = (heading) => {
		return (
			<div key={heading} className="section-heading-wrapper">
				<h3 className="section-heading">{heading}</h3>
				{this.props.sectionSubheadings && this.props.sectionSubheadings[heading] ? (
					<div className="section-subheading">{this.props.sectionSubheadings[heading]}</div>
				) : null}
			</div>
		);
	};

	renderMenuSubheading = (subHeading) => {
		return (
			<h4 key={subHeading} className="menu-subheading">
				{subHeading}
			</h4>
		);
	};
	renderMenu = () => {
		if (!this.state.activeMenu || !this.menus[this.state.activeMenu]) return null;
		let itemCount = 0;
		let menu = [];
		let usedSections = {};
		if (this.props.menuSubheadings && this.props.menuSubheadings[this.state.activeMenu]) {
			menu.push(this.renderMenuSubheading(this.props.menuSubheadings[this.state.activeMenu]));
		}
		for (let i = 0; i < this.menus[this.state.activeMenu].length; i++) {
			let currentItem = this.menus[this.state.activeMenu][i];
			if (!usedSections[currentItem['section']]) {
				menu.push(this.renderSectionHeading(currentItem['section']));
				usedSections[currentItem['section']] = 1;
			}
			let itemScore = currentItem['score'] ? currentItem['score'] : 0;
			if ((this.props.binary & itemScore) == 0 && !currentItem.isHidden) {
				itemCount++;
				menu.push(
					<Item
						binary={this.props.binary}
						restaurantOrder={this.props.restaurantOrder}
						disableContains={this.props.disableContains}
						disabledButtonNames={this.props.disabledButtonNames}
						disableIcons={this.props.disableIcons}
						disablePrice={this.props.disablePrice}
						clickItem={this.props.clickItem}
						item={currentItem}
						key={String(i)}
						toggleModal={this.props.toggleModal}
					/>
				);
			}
		}
		if (itemCount == 0) return this.noOptionsAvailable;
		return menu;
	};

	renderMenuButtons = () => {
		let menuButtons = [];
		let menus = Object.keys(this.menus).reverse();
		menus = this.sortMenus(menus);
		for (let i = 0; i < menus.length; i++) {
			if (this.state.activeMenu != menus[i]) {
				menuButtons.push(
					<a
						key={String(i)}
						className="menu-choice-button"
						onClick={() => this.setState({ activeMenu: menus[i] })}
					>
						{menus[i]}
					</a>
				);
			}
		}
		return menuButtons;
	};

	renderDropdown() {
		return <MenuChooser menus={Object.keys(this.menus)} selectActiveMenu={this.selectMenu} />;
	}

	renderMenuHeader = () => {
		return (
			<div id="menu-header-wrapper">
				<div className="menu-select">SELECT MENU: </div>
				<div id="menu-options-wrapper">
					<div
						id="active-menu-heading"
						onClick={this.toggleRenderDropdown}
						data-actived={this.state.shouldRenderDropdown}
					>
						{this.state.activeMenu}
						<svg
							width="24px"
							height="24px"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17 11.7494V14.916L12 11.0827L7 14.916V11.7494L12 7.91602L17 11.7494Z"
								fill="#1F1F1F"
								transform="rotate(180, 12, 12)"
							/>
						</svg>
						{this.state.shouldRenderDropdown && this.renderDropdown()}
					</div>

					<div id="menu-header">{this.renderMenuButtons()}</div>
				</div>
			</div>
		);
	};

	/**
     * Sorts the menu objects contained in items in the following manner:
     * 1) Groups them all by section 
     * 2) Sorts each of them based on section orderings if orderings exist
     * 3) Sorts each of them by menus 
     * Total complexity: 3nlog(n) for number of items but sorts 2 and 3 are fast
     * 
     */
	async componentWillMount() {
		let fullMenu = this.props.items;
		let that = this;
		if (fullMenu) {
			fullMenu.sort(function(a, b) {
				let sectiona = a['section'] ? a['section'].trim() : 'menu';
				let sectionb = b['section'] ? b['section'].trim() : 'menu';
				if (sectiona < sectionb) return -1;
				if (sectiona > sectionb) return 1;
				return 0;
			});
			fullMenu.sort(function(a, b) {
				let sectiona = a['section'] ? a['section'].trim() : 'menu';
				let sectionb = b['section'] ? b['section'].trim() : 'menu';
				let aSectionValue =
					that.props.sectionOrderings && that.props.sectionOrderings[sectiona]
						? that.props.sectionOrderings[sectiona]
						: 0;
				let bSectionValue =
					that.props.sectionOrderings && that.props.sectionOrderings[sectionb]
						? that.props.sectionOrderings[sectionb]
						: 0;
				if (aSectionValue < bSectionValue) return -1;
				else if (bSectionValue < aSectionValue) return 1;
				else return 0;
			});

			for (let i = 0; i < fullMenu.length; i++) {
				let item = fullMenu[i];
				let itemMenu = item['Category'] ? item['Category'].trim() : 'menu';
				if (itemMenu in this.menus) {
					await this.menus[itemMenu].push(item);
				} else {
					await (this.menus[itemMenu] = [ item ]);
				}
			}
			let keys = Object.keys(this.menus).reverse();
			keys = this.sortMenus(keys);
			this.setState({ activeMenu: keys[0] });
		}
	}

	render() {
		return (
			<div>
				{this.renderMenuHeader()}
				<div className="menu-wrapper">{this.renderMenu()}</div>
			</div>
		);
	}
}

export default Menu;
