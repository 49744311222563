import React, { useState, useEffect, useRef } from 'react';
import Profile from './Profile';
import Menu from './Menu';
import ItemModal from './ItemModal';
import Modal from './Modal';


const useSwipeGesture = () => {
    const [ gestureData, setGestureData ] = useState({
        start_postion: [ 0, 0 ],
        end_position: [ 0, 0 ],
        detection: [ 0, 0 ]
    });

    const onTouchStart = (e) => {
        const { clientX, clientY } = e.touches[0];
        setGestureData((value) => ({
            ...value,
            detection: [ 0, 0 ],
            start_postion: [ clientX, clientY ]
        }));
    };
    const onTouchEnd = (e) => {
        const scrollTop = e.currentTarget.scrollTop;
        setGestureData((value) => ({
            ...value,
            scrollTop,
            detection: [
                value.start_postion[0] - value.end_position[0],
                value.start_postion[1] - value.end_position[1]
            ]
        }));
    };

    const onTouchMove = (e) => {
        e.currentTarget.blur();
        const { clientX, clientY } = e.touches[0];
        setGestureData((value) => ({
            ...value,
            end_position: [ clientX, clientY ]
        }));
    };

    return {
        ...gestureData,
        handlers: { onTouchStart, onTouchEnd, onTouchMove }
    };
};
const MenuSection = (props) => {
    const { items, disableContains, disablePrice, disabledButtonNames, restaurantOrder } = props;
    const [ binary, setBinary ] = useState(0);
    const [ shouldRenderItems, setShouldRenderItems ] = useState(true);
    const [ shouldRenderItemModal, setShouldRenderItemModal ] = useState(false);
    const [ activeItem, setActiveItem ] = useState({});
    const { scrollTop, detection, handlers } = useSwipeGesture();
    const ref = useRef(null);
    const toggleBinary = (propBinary) => {
        setBinary(
            (binary) =>
                (binary & propBinary) == 0
                    ? binary | propBinary
                    : binary & ~propBinary
        );
    };

    const clearBinary = () => setBinary(0);
    const toggleMenuVisibility = () => setShouldRenderItems((value) => !value);
    const toggleModal = (item) => {
        setShouldRenderItemModal((value) => !value);
        setActiveItem(item);
    };
    const closeModal = () => {
        setShouldRenderItemModal(false);
        setActiveItem({});
    };

    useEffect(
        () => {
            const [ xMove, yMove ] = detection;

            if (scrollTop <= 0 && yMove < -30) {
                // setShouldRenderItemModal((value) => !value);e
            }
        },
        [ detection, ref, scrollTop ]
    );
    return (
        <div className='menu-section'>
            <div className='w-container'>
                <Profile
                    {...props}
                    binary={binary}
                    toggleBinary={toggleBinary}
                    clearBinary={clearBinary}
                    setBinary={setBinary}
                    toggleMenuVisibility={toggleMenuVisibility}
                />

                {shouldRenderItems && (
                    <Menu
                        {...props}
                        binary={binary}
                        toggleModal={toggleModal}
                        items={items}
                    />
                )}
                <Modal
                    contentRef={ref}
                    handlers={handlers}
                    className='menu-modal'
                    actived={shouldRenderItemModal}
                    setActived={closeModal}>
                    <span
                        className='modal-close-button'
                        data-no-image={Boolean(activeItem.image)}
                        onClick={closeModal}>
                        &times;
                    </span>
                    <ItemModal
                        binary={binary}
                        item={activeItem}
                        shouldRenderItemModal={shouldRenderItemModal}
                        disableContains={disableContains}
                        disablePrice={disablePrice}
                        disabledButtonNames={disabledButtonNames}
                        restaurantOrder={restaurantOrder}
                    />
                   
                </Modal>
            </div>
        </div>
    );
};

export default MenuSection;
