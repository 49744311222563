import React from 'react';

const Ad = () => {
    return (
        <div onClick={() =>
            window.open(
                'https://get.honeycomb.ai?utm_source=webplugin',
                '_blank'
            )}
            className="app-banner w-container">
            <div className="w-row">
                <div className="w-col w-col-7">
                    <div className="app-preorder-title">Want to find suitable meals at other restaurants?</div>
                </div>
            </div>
            <div className="w-row">
                <div className="w-col w-col-7 w-col-small-6 w-col-tiny-6">
                    <div className="banner-description">Combine multiple diets and allergens, see full menus, explore local restaurants and order directly - all through our mobile app.</div>
                    <a href="https://get.honeycomb.ai?utm_source=webplugin" target="_blank">
                        <div className="pre-order-button">Pre-Order</div>
                    </a>
                </div>
                <div className="w-col w-col-5 w-col-small-6 w-col-tiny-6">
                    <img className="ad-image" src="images/ad.png"></img>
                </div>
            </div>
        </div>
    )
}

export default Ad;