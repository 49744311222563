import React from 'react';

export const GenericModal = ({name, description}) => {
    return (
        <div className='w-container'>
            <div className='information-div w-clearfix'>
                <div className="info-name">{name}</div>
                    <div className="info-sub">{description}</div>
            </div>
        </div>
    );
}
