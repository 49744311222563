import React, {  useMemo, useCallback, useState, useRef, useEffect } from 'react';

import { BINARY, ITEM_IMAGE_URL, NUTRITION_COLOR, ALLERGEN_INFORMATION } from '../Config/config';

import { getCalorieSize } from '../Config/utils';

import ReactMinimalPieChart, { PieChart } from 'react-minimal-pie-chart';

const hideStyle = (check) => ({ display: check ? 'none' : '' });

const ItemIngredientsButton = ({ item, index, onClick, isSelected }) => {
	const { className, content } = item;
	return (
		<div key={index} data-selected={isSelected} className={className} onClick={() => onClick({ content, index })}>
			{content}
		</div>
	);
};
const AllergenItem = ({ item = {} }) => {
	const { description, image } = item;
	return (
		<div className="allergen-item">
			<img src={image} alt='allergen' />
			<p>{description}</p>
		</div>
	);
};
const ItemIngredientsButtons = ({ shouldRenderItemModal, disabledButtonNames, item = {}, itemComponent }) => {
	const { score, modifiableVector } = item;
	const [ selected, setSelected ] = useState({});

	const _onClick = useCallback(({ content, index }) => {
		let allergen = ALLERGEN_INFORMATION[content] || {};
		setSelected({ ...allergen, index });
	}, []);
	const memoIngredients = useMemo(
		() => {
			let ingredients = [];
			for (let i = 0; i < BINARY.length; i++) {
				let contains = score & (1 << i);
				let mod = modifiableVector & (1 << i);
				if (contains > 0) {
					if(!disabledButtonNames){
						ingredients.push({
							className: 'modal-contains-button',
							content: BINARY[BINARY.length - i - 1]
						});
					} else if (disabledButtonNames[BINARY[BINARY.length - 1 - i]] != 1) {
						ingredients.push({
							className: 'modal-contains-button',
							content: BINARY[BINARY.length - i - 1]
						});
					}

				} else if (mod > 0) {
					ingredients.push({
						className: 'modal-modifiable-button',
						content: BINARY[BINARY.length - i - 1]
					});
				}
			}
			return ingredients;
		},
		[ score, modifiableVector ]
	);
	useEffect(() => {
		if (!Boolean(shouldRenderItemModal)){
			setSelected({})
		}
		return ()=>{}
	 }, [shouldRenderItemModal])

	return (
		<div className="modal-allergen-description">
			{memoIngredients.map((item, index) =>
				itemComponent(
					{
						item,
						key: index,
						onClick: _onClick,
						index,
						isSelected: selected.index === index
					},
					index
				)
			)}
			{selected.description && <AllergenItem item={selected} />}
		</div>
	);
};

const ItemImage = ({ item = {} }) => {
	const { image } = item;
	return (
		<div className="item-hero-image-wrapper">
			<img className="item-hero-image" src={ITEM_IMAGE_URL(image)} alt='hero' />
		</div>
	);
};

const ItemHeader = ({ item = {} }) => {
	const { name, description } = item;

	return (
		<div className="modal-item-header">
			<div className="modal-item-title">{name}</div>
			<div className="modal-item-description">{description}</div>
		</div>
	);
};
const ItemAdditional = ({ item = {} }) => {
	const { name, Additional } = item;
	return (
		<div className="modal-item-header" data-name={name} style={hideStyle(!Additional)}>
			<div className="modal-item-title">Additional Info</div>
			<div className="modal-item-description">{Additional}</div>
		</div>
	);
};
const ItemContents = ({ item = {}, shouldRenderItemModal, disableContains, disabledButtonNames  }) => {
	const { modifiableVector } = item;
	return (
		<div className="modal-contains-wrapper">
			<div className="modal-key-contents-text">Key Contents: </div>
			{modifiableVector ? (
				<div className="modal-modifiable-header">
					<span className="modal-modifiable-dot">●</span>
					<span className="modal-modifiable-text">Can be made without</span>
				</div>
			) : null}
			<div className="modal-ingredients-wrapper">
				<ItemIngredientsButtons
					item={item}
					shouldRenderItemModal={shouldRenderItemModal}
					disabledButtonNames={disabledButtonNames}
					itemComponent={(ingredient) => <ItemIngredientsButton {...ingredient} />}
				/>
			</div>
		</div>
	);
};
function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
const ChartLabel = (props) => {
	const { data = [], key } = props;
	const [ item = {} ] = data;
	const { grams, label } = item;
	return (
		<text
			key={key}
			textAnchor="middle"
			dominantBaseline="middle"
			fill="#FFF"
			x="50"
			y="50"
			dx="0"
			dy="0"
			style={{ fontFamily: 'Lato', fontSize: '10px' }}
		>
			<tspan x="50" dy="-9px">
				{grams + ' g'}
			</tspan>
			<tspan x="50" dy="18px">
				{capitalize(label)}
			</tspan>
		</text>
	);
};
const GraphChart = ({ label, value, grams, calories }) => {
	return (
		<div className="item-graph-chart">
			<PieChart
				className="item-chart"
				animate={true}
				animationDuration={500}
				animationEasing="ease-out"
				cx={50}
				cy={50}
				data={[
					{
						// color: NUTRITION_COLOR[label],
						value: Math.round(value / calories * 100),
						color: '#0dbdf5',
						label,
						grams
					}
				]}
				//label={ChartLabel(label, value, grams, calories)}
				// label
				label={({dataEntry}) => dataEntry.grams + ' g ' + capitalize(dataEntry.label) }			
				labelStyle={(index) => ({ fontFamily: 'Lato', fontSize: '10px', fill: 'white' })}
				labelPosition={0}
				lengthAngle={360}
				lineWidth={10}
				paddingAngle={0}
				radius={50}
				ratio={1}
				rounded={false}
				startAngle={270}
				totalValue={100}
			/>
			<div className="bg-wrapper">
				<div className="bg" />
			</div>
		</div>
	);
};
const ItemNutrition = ({ item = {} }) => {
	const { calories, portionSize, carbs, protein, fat, sugar, sodium, fibre, cholesterol } = item;

	return !portionSize || !calories ? (
		<div style={{ minHeight: '220px' }} />
	) : (
		<div className="nutritional-info-wrapper ">
			<div className="nutritional-info-header-wrapper">
				<div className="nutritional-info-header">
					<div className="nutritional-header-text">Nutritional Facts</div>

					{/* TODO: uncomment this div when we realize where it should link to.
                        Note - any commented out code should only be done so in situations 
                        like this where a TODO exists or there is an explanation 
                    <div className="info-icon">i</div> */}
				</div>
			</div>
			<div className="nutritional-content-wrapper">
				<div className="nutritional-content-row nutrition-top-row">
					<div className="nutritional-content-item-label">
						Serving Size: <span>{portionSize}g</span>
					</div>
					<div className="nutritional-content-item-label">
						Calories: <span>{calories}</span>
					</div>
				</div>

				<div className="nutritional-chart-wrapper">
					<div className="nutritional-charts">
						<div className="nutrition-chart">
							{portionSize && (
								<GraphChart
									label="carbs"
									value={getCalorieSize('carbs', carbs)}
									grams={carbs}
									calories={calories}
								/>
							)}
						</div>
						<div className="nutrition-chart">
							{portionSize && (
								<GraphChart
									label="protein"
									value={getCalorieSize('protein', protein)}
									grams={protein}
									calories={calories}
								/>
							)}
						</div>
						<div className="nutrition-chart">
							{portionSize && (
								<GraphChart
									label="fat"
									value={getCalorieSize('fat', fat)}
									grams={fat}
									calories={calories}
								/>
							)}
						</div>
					</div>
					<div className="nutritional-chart-description">
						** Percentages show proportionately how many calories in this dish come from each macronutrient
					</div>
				</div>

				<div className="nutritional-content-row nutritional-content-value-wrapper">
					<div className="nutritional-content-value">
						<span className="nutritional-content-label">Sugar: </span>
						<span className="nutritional-content-amount">{`  ` + sugar + 'g'}</span>
					</div>
					<div className="nutritional-content-value">
						<span className="nutritional-content-label">Sodium: </span>
						<span className="nutritional-content-amount">{`  ` + sodium + 'mg'}</span>
					</div>
					<div className="nutritional-content-value">
						<span className="nutritional-content-label">Fibre: </span>
						<span className="nutritional-content-amount">{`  ` + fibre + 'g'}</span>
					</div>
					<div className="nutritional-content-value">
						<span className="nutritional-content-label">Cholesterol: </span>
						<span className="nutritional-content-amount">{`  ` + cholesterol + 'g'}</span>
					</div>
				</div>
			</div>
		</div>
	);
};
const SubmitButton = ({ bgColor, icon, restaurantOrder }) => {
	return (
		<div className='item-modal-submit-button' style={{ backgroundColor: bgColor }}>
			{restaurantOrder ? (
				<a
					target='_blank'
					href={restaurantOrder}
					>
					<span>
						Order <span>{icon}</span>
					</span>
				</a>
			) : (
					<div disabled>
						<span>{/* Order <span>{icon}</span> */}</span>
					</div>
				)}
		</div>
	);
};
const ItemModal = (props) => {
	const { binary, item = {}, disableContains, disablePrice, disabledButtonNames, shouldRenderItemModal, restaurantOrder, ...rest } = props;
	const ref = useRef(null);
	useEffect(()=>{
		ref.current.scrollTop=0;
		window.scrollTo(0,0);
	
		return ()=>{
			ref.current.scrollTop = 0;
			ref.current.scrollTo(0,0);

		}
	},[])
	
	return (
		<div style={{position:'relative'}}>
			<div className="w-row item-hero-container" {...rest} ref={ref}>
				{item.image && <ItemImage item={item} />}
				<ItemHeader item={item} />
				{!disableContains && !!(item.score || item.modifiableVector) && <ItemContents shouldRenderItemModal={shouldRenderItemModal} disabledButtonNames={disabledButtonNames} item={item} />}
				<ItemAdditional item={item} />
				<ItemNutrition item={item} />

			</div>
			<SubmitButton restaurantOrder={restaurantOrder} />
		</div>
		
	);
};

export default ItemModal;
