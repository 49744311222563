import React, { Component, useMemo } from 'react';

import { BINARY, PRESET_DIETS } from '../Config/config';

import NUTRITION_ICON from '../assets/images/nutrition-icon.svg';

const DietTag = ({ item }) => {
	const { image, text } = item;
	return (
		<div data-name={text} className="dietbutton active">
			<img src={image} width="25" className="dietimg" />
			<div className="diettext dt3">{text}</div>
		</div>
	);
};
const BinaryDietList = ({ binary }) => {
	const memosTags = useMemo(
		() => {
			let tags = [];
			if ((binary & PRESET_DIETS.PALEO) == PRESET_DIETS.PALEO) {
				tags.push({
					image: 'images/diets/paleo_diet.svg',
					text: 'PALEO'
				});
			}
			if ((binary & PRESET_DIETS.VEGAN) == PRESET_DIETS.VEGAN) {
				tags.push({
					image: 'images/diets/vegan_diet.svg',
					text: 'VEGAN'
				});
			}
			if ((binary & PRESET_DIETS.FODMAP) == PRESET_DIETS.FODMAP) {
				tags.push({
					image: 'images/diets/fodmap_diet.svg',
					text: 'FODMAP'
				});
			}
			if ((binary & PRESET_DIETS.VEGETARIAN) == PRESET_DIETS.VEGETARIAN) {
				if ((binary & PRESET_DIETS.VEGAN) != PRESET_DIETS.VEGAN) {
					tags.push({
						image: 'images/diets/vegetarian_diet.svg',
						text: 'VEGETARIAN'
					});
				}
			}
			if ((binary & PRESET_DIETS['GLUTEN-FREE']) == PRESET_DIETS['GLUTEN-FREE']) {
				tags.push({
					image: 'images/diets/gf_diet.svg',
					text: 'GLUTEN-FREE'
				});
			}
			if ((binary & PRESET_DIETS.KETO) == PRESET_DIETS.KETO) {
				tags.push({
					image: 'images/diets/keto_diet.svg',
					text: 'KETO'
				});
			}
			return tags;
		},
		[ binary ]
	);
	return (
		<div className="item-diet-tags-wrapper">
			{memosTags.map((item, index) => {
				return <DietTag item={item} />;
			})}
		</div>
	);
};

class Item extends Component {
	getIngredientsText = (score, modifiableVector) => {
		let ingredients = '';
		for (let i = 0; i < BINARY.length; i++) {
			let contains = score & (1 << i);
			let mod = modifiableVector & (1 << i);
			if (mod > 0 || contains > 0){
				if(this.props.disabledButtonNames && this.props.disabledButtonNames[BINARY[BINARY.length - 1 - i]] != 1){
					ingredients += BINARY[BINARY.length - 1 - i].toLowerCase() + ', ';
				}
				if(!this.props.disabledButtonNames){
					ingredients += BINARY[BINARY.length - 1 - i].toLowerCase() + ', ';
				}
			}
		}
		return ingredients.slice(0, ingredients.length - 2);
	};

	get calories() {
		return <div className="item-calories">{this.props.item.calories + ' Cal'}</div>;
	}

	get ingredients() {
		if (!this.props.item['score'] || this.props.disableContains) return null;
		return (
			<div className="ingredients-wrapper">
				<strong>Key Contents: </strong>
				{this.getIngredientsText(this.props.item.score, this.props.item.modifiableVector)}
				{this.modifiable}
			</div>
		);
	}

	get modifiable() {
		if (!this.props.item['modifiableVector']) return null;
		return (
			<div className="moddiv">
				<div className="modifiableblue">Modifiable For You</div>
				<div className="ingredients">
					<strong>can be made without: </strong>
					{this.getIngredientsText(0, this.props.item.modifiableVector)}
				</div>
			</div>
		);
	}

	get nutritionalInfo() {
		return (
			<div className="nutrition-button-wrapper">
				<div className="nutrition-button-text">
					{this.props.item['calories'] ? (
						<img className="nutrition-button-image" src={NUTRITION_ICON} width="20px" />
					) : (
						<span className="more-details-plus">+ </span>
					)}
					More details
				</div>
			</div>
		);
	}

	render() {
		return (
			<div
				className="item-div"
				onClick={() => {
					this.props.toggleModal(this.props.item);
				}}
			>
				<div className="iteminfowrapper w-clearfix">
					{!this.props.disablePrice ? <div className="itemprice">{this.props.item['price']}</div> : null}
					<div className="itemname">{this.props.item['name']}</div>
					<div className="itemdescription">{this.props.item['description']}</div>
					{this.ingredients}
					{this.props.item.calories && this.calories}
					<BinaryDietList binary={this.props.binary} />
					{this.nutritionalInfo}
				</div>
			</div>
		);
	}
}

export default Item;
