module.exports = {
	// Module type where this event has been tracked
	sources: {
		APP_SOURCE: 1,
		WEB_SOURCE: 2
	},
	actions: {
		/* Code of event types. 
        * Beside each event is an indication of additional data of that type
        * to be specified in data field
        */

		//WEB EVENTS
		MENU_VIEW_EVENT: 1, //None
		DIET_CLICK_EVENT: 2, //Diet Name
		ALLERGEN_CLICK_EVENT: 3, //Allergen Name
		ITEM_CLICK_EVENT: 4, //Item Name
		MENU_CHANGE_EVENT: 5, //Menu Name
		FILTER_CLICK_EVENT: 6, //None
		ORDER_CLICK_EVENT: 7, //None
		INFO_CLICK_EVENT: 8, //None

		//MOBILE EVENTS
		MENU_VIEW_MOB: 101, //None
		ITEM_VIEW: 102, //Item Name
		MAP_VIEW: 103, //None
		LIST_VIEW: 104, //None
		FAV_VIEW: 105, //None
		PROF_VIEW: 106, //None
		DIET_UPDATE: 107, //Diet Name (string)
		BIN_UPDATE: 108, //Diet Vector (int)
		RESTAURANT_SEARCH: 109, //Search Term (string)
		ITEM_SEARCH: 110 //Search Term(string)
	}
};
