export const truncateString = (inString, maxSize) => {
    return (inString.length > maxSize) ? inString.substr(0, maxSize-1) + '...' : inString;
}

export const getCalorieSize = (label, value) =>  {
    switch (label) {
        case 'fat':
            value = Math.round((value * 9)) 
            break;
        
        case 'protein':
            value = Math.round((value * 4)) 
            break;            
    
        case 'carbs':
            value = Math.round((value * 4)) 
            break;

        default:
            break;
    }
    return value; 
}