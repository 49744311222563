import React from 'react';

const Footer = () => {
    return (
        <div className="footer-wrapper">
            <div className="w-container">
                <div className="footerdiv">
                    <div className="poweredtext">Powered by <span><a href="http://eat.app.link/web" target="_blank" className="honeylink"><img className="logo" src="images/logo-black.png"></img></a></span></div>
                </div>
            </div>
        </div>
    )
}

export default Footer;